/*
* Image Attributions
<a href="https://www.freepik.com/free-vector/global-data-security-personal-data-security-cyber-data-security-online-concept-illustration-internet-security-information-privacy-protection_12953631.htm#query=data%20security&position=13&from_view=search&track=ais">Image by jcomp</a> on Freepik
<a href="https://www.freepik.com/free-vector/office-workers-analyzing-researching-business-data_6974920.htm#query=research&position=18&from_view=search&track=sph">Image by pch.vector</a> on Freepik
<a href="https://www.freepik.com/free-vector/business-audit-financial-specialist-cartoon-character-with-magnifier-examination-statistical-graphic-information-statistics-diagram-chart-vector-isolated-concept-metaphor-illustration_12083507.htm#query=data&position=9&from_view=search&track=sph">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/corporate-workers-achieving-success-business_14623828.htm#query=opportunity&position=24&from_view=search&track=sph">Image by pch.vector</a> on Freepik

<a href="https://www.freepik.com/free-vector/hand-drawn-colorful-innovation-concept_20286053.htm#query=collaborate&from_query=collabrate&position=9&from_view=search&track=sph">Image by pikisuperstar</a> on Freepik
Image by <a href="https://www.freepik.com/free-vector/flat-youth-day-jumping-people_8845535.htm#query=fun&position=0&from_view=search&track=sph">Freepik</a>
<a href="https://www.freepik.com/free-vector/employees-celebrating-business-success-with-huge-trophy_12953798.htm#query=prize&position=10&from_view=search&track=sph">Image by jcomp</a> on Freepik
<a href="https://www.freepik.com/free-vector/characters-people-their-social-network-illustration_3046728.htm#query=personal%20network&position=25&from_view=search&track=ais">Image by rawpixel.com</a> on Freepik
<a href="https://www.freepik.com/free-vector/focused-tiny-people-reading-books_8609147.htm#query=learning&position=13&from_view=search&track=sph">Image by pch.vector</a> on Freepik
https://www.freepik.com/free-vector/maths-online-course-economics-university-department-internet-classes-accounting-lessons-bookkeeping-mathematics-textbooks-digital-archive_12145563.htm#from_view=detail_author
<a href="https://storyset.com/people">People illustrations by Storyset</a>

https://www.freepik.com/free-vector/honour-abstract-concept-vector-illustration-show-esteem-achievement-award-high-moral-principles-college-graduate-public-respect-medal-emblem-honour-memorial-noble-people-abstract-metaphor_12468711.htm#query=certificate&position=1&from_view=author
https://www.freepik.com/free-vector/international-business-cooperation-businesswoman-businessman-shaking-hands-global-collaboration-agreement-international-partnership_10780343.htm#query=professional%20network&position=32&from_view=author
https://www.freepik.com/free-vector/successful-businessman-running-up-career-stairs-rising-arrow-star-career-growth-careerbuilder-career-development-concept_10782697.htm#query=professional%20growth&position=0&from_view=author
https://www.freepik.com/free-vector/business-people-working-modern-eco-friendly-office-with-plants-flowers-biophilic-design-room-eco-friendly-workspace-green-office-concept-bright-vibrant-violet-isolated-illustration_10780696.htm#query=work&position=4&from_view=author

<a href="https://www.freepik.com/free-vector/surfing-web-browsing-through-websites-free-internet-network-public-wi-fi-hotspot-free-wireless-internet-access-free-wifi-service-concept-bright-vibrant-violet-isolated-illustration_10780536.htm#query=network&position=32&from_view=author">Image by vectorjuice</a> on Freepik
Icon colors
rgba(28, 127, 242, 1)
rgba(104, 34, 236, 1)

<a href="https://www.freepik.com/free-vector/youth-empowerment-abstract-concept-illustration-children-young-people-take-charge-take-action-improve-life-quality-democracy-building-youth-activism-involvement_10783087.htm#query=group&position=16&from_view=author">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/tiny-people-using-technological-innovations-digital-device-technological-revolution-modern-scientific-innovations-technological-progress-concept-bright-vibrant-violet-isolated-illustration_10782930.htm#query=technology&position=0&from_view=author">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/sales-pitch-data-visualization-element-marketing-chart-research-data-business-statistics-financial-report-company-performance-analysis-concept_10780218.htm#query=stats&position=27&from_view=author">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/profitable-partnership-business-partners-cowork-affiliate-marketing-cost-effective-marketing-solution-affiliate-marketing-management-concept_10780044.htm#page=2&query=support&position=0&from_view=author">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/clients-assistance-call-center-hotline-operator-consultant-manager-customer-care-seamless-personalized-service-customer-experience-concept_10780155.htm#page=3&query=help&position=8&from_view=author">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/businessman-with-trophy-running-rising-arrow-business-mission-mission-statement-business-goals-concept_10780235.htm#query=achievement&position=34&from_view=author">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/group-friends-sitting-table-talking-drinking-coffee-tea-tiny-people-friends-meeting-cheer-up-friend-friendship-support-concept_10782457.htm#query=group&position=5&from_view=author">Image by vectorjuice</a> on Freepik
<a href="https://www.freepik.com/free-vector/tiny-people-high-school-students-dresses-suits-chatting-promenade-dance-prom-party-prom-night-invitation-promenade-school-dance-concept_10782513.htm#query=party&position=8&from_view=author">Image by vectorjuice</a> on Freepik

<a href="https://storyset.com/data">Data illustrations by Storyset</a>
<a href="https://storyset.com/people">People illustrations by Storyset</a>
<a href="https://storyset.com/web">Web illustrations by Storyset</a>
<a href="https://storyset.com/worker">Worker illustrations by Storyset</a>
<a href="https://storyset.com/business">Business illustrations by Storyset</a>
<a href="https://storyset.com/business">Business illustrations by Storyset</a>
<a href="https://storyset.com/work">Work illustrations by Storyset</a>
<a href="https://storyset.com/people">People illustrations by Storyset</a>

* Default Theme
--theme-color: #4154f1;
--theme-color-dark: #012970; #0f3984;
--theme-counter-color: #0b198f;
*/

:root {
    scroll-behavior: smooth;
    --theme-color: #1c7ff2;
    --theme-color-hover: #0d64c8;
    --theme-color-dark: #00409a;
    --theme-counter-color: #225794;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #d6e9ff;
}

::-webkit-scrollbar-thumb {
    background: #5ea9ff;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--theme-color);
}

body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
}

a {
    color: var(--theme-color);
    text-decoration: none;
}

button.btn.btn-primary {
    background: var(--theme-color);
}

a:hover {
    color: var(--theme-color);
    text-decoration: none;
}

.link {
    color: var(--theme-color);
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Open Sans", sans-serif;
}

/* Sections */
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-header {
    text-align: center;
    padding-bottom: 40px;
}

.section-header h2,
#headingMutate {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: var(--theme-color);
    text-transform: uppercase;
}

.section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: var(--theme-color-dark);
    font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
    .section-header p {
        font-size: 28px;
        line-height: 32px;
        cursor: auto;
    }
}

/* Breadcrumbs */
.breadcrumbs {
    padding: 15px 0;
    background: #012970;
    min-height: 40px;
    margin-top: 82px;
    color: #fff;
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 57px;
    }
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 500;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol a {
    color: #fff;
    transition: 0.3s;
}

.breadcrumbs ol a:hover {
    text-decoration: underline;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #8894f6;
    content: "/";
}

/* Back to top button */
/* .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: var(--theme-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: var(--theme-color-hover);
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
} */

/* Disable aos animation delay on mobile devices */
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/* Header */
.header {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header.header-scrolled {
    background: rgb(255 255 255 / 100%);
    padding: 15px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    /* backdrop-filter: blur(50px); */
}

.header .logo {
    line-height: 0;
}

.header .logo img {
    max-height: 50px;
    margin-right: 6px;
}

.header .logo span {
    font-size: 25px;
    letter-spacing: 1px;
    color: #012970;
    font-family: "Open Sans", sans-serif;
    margin-top: 3px;
}

/* Navigation Menu */
/**
* Desktop Navigation 
*/
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #013289 !important;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a,
a.nav-link.forceActive,
span.nav-link.forceActive {
    color: var(--theme-color) !important;
}

.navbar .getstarted,
.navbar .getstarted:focus,
.logout-btn {
    background: var(--theme-color);
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff;
}

a.getstarted.scrollto {
    color: #fff !important;
    background: var(--theme-color);
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover,
a.getstarted.scrollto:hover {
    color: #fff !important;
    background: var(--theme-color-hover);
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: var(--theme-color-hover);
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #012970;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: var(--theme-color-hover);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: var(--theme-color-hover);
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/* Footer */
.footer {
    background: #f6f9ff;
    padding: 0 0 30px 0;
    font-size: 14px;
}

.footer .footer-newsletter {
    padding: 50px 0;
    background: #f6f9ff;
    border-top: 1px solid #e1ecff;
}

.footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    color: #012970;
}

.footer .footer-newsletter form,
.subscribe-form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type="email"],
.footer .footer-newsletter form input[type="text"],
.subscribe-form input[type="email"],
.subscribe-form input[type="text"] {
    border: 0;
    padding: 8px;
    width: calc(100% - 116px);
    outline: none;
}

.footer .footer-newsletter form input[type="submit"],
.subscribe-form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 22px;
    margin: 3px;
    background: var(--theme-color);
    color: #fff;
    transition: 0.3s;
    border-radius: 4px;
}

.footer .footer-newsletter form input[type="submit"]:hover,
.subscribe-form input[type="submit"]:hover {
    background: var(--theme-color-hover);
}

.footer .footer-top {
    background: white url(../../assets/img/global/footer-bg.png) no-repeat right top;
    background-size: contain;
    border-top: 1px solid #e1ecff;
    border-bottom: 1px solid #e1ecff;
    padding: 60px 0 30px 0;
}

@media (max-width: 992px) {
    .footer .footer-top {
        background-position: center bottom;
    }
}

.footer .footer-top .footer-info {
    margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
    line-height: 0;
    margin-bottom: 15px;
}

.footer .footer-top .footer-info .logo img {
    max-height: 40px;
    margin-right: 6px;
}

.footer .footer-top .footer-info .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: "Open Sans", sans-serif;
    margin-top: 3px;
}

.footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
}

.footer .footer-top .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
    color: var(--theme-color);
}

.footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #012970;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
}

.footer .footer-top .footer-links {
    margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #d0d4fc;
    font-size: 12px;
    line-height: 0;
}

.footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-top .footer-links ul a {
    color: #013289;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
    color: var(--theme-color);
}

.footer .footer-top .footer-contact p {
    line-height: 26px;
}

.footer .copyright {
    text-align: center;
    padding-top: 30px;
    color: #012970;
}

.footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #012970;
}

/*
* Mutate Previous Properties
*/
.l {
    color: var(--theme-color);
}

input[type="email"],
input[type="text"] {
    display: block;
}

section#clients {
    margin-top: -55px;
}

.TnC {
    color: var(--theme-color-dark);
    cursor: pointer;
}

.dropdown-divider {
    border-top: 1px solid #dadada;
    margin: 8px 0;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(28 127 242);
}

.swal2-styled.swal2-confirm {
    background-color: var(--theme-color);
}

.swal2-icon.swal2-warning {
    border-color: #1c7ff2;
    color: #1c7ff2;
}

.swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 10000;
}

.hidden {
    display: none;
}

section.legalPageWrap {
    display: block;
    position: relative;
    width: 80%;
    left: 10%;
    margin-top: 90px;
    margin-bottom: 25px;
    background: rgb(0 0 0 / 10%);
    padding: 10px 15px;
    margin-right: -15px;
    color: #000000b5;
    font-family: monospace;
    font-size: 14px;
}

section.legalPageWrap h2 {
    font-family: monospace;
}

.msSpin {
    display: block;
    position: relative;
    width: 100%;
    margin: 25px 0;
    overflow: hidden;
}

#hero.partner-bg h2 {
    text-align: justify;
    font-size: x-large;
    font-family: "Open Sans", sans-serif;
}

.shimmer {
    margin: 0 auto;
    background-color: #eee;
    border-radius: 7px;
    overflow: hidden;
}

.shimmer-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right,
            #eeeeee 10%,
            #dddddd 18%,
            #eeeeee 33%);
    background-size: 800px 104px;
    height: 100px;
    position: relative;
    color: transparent !important;
}

.shimmer-round {
    border-radius: 5px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.event-card {
    background: white;
}

.shimmer-h {
    height: 50px;
}

.shimmer-img {
    height: 165px;
}

iframe.gallery {
    width: 100%;
    min-height: 75vh;
}

.wBg {
    background: white !important;
}


.dashboardLoaderWidget.pricingLoaderWidget {
    position: relative;
    width: 100%;
    top: 0;
    left: 0 !important;
    min-height: 80vh;
}

.noLink {
    color: inherit;
}